<template>
<div class="card-box-full">
  <div class="card-box-header">
    <table width="100%">
      <tr>
        <td align="left">
          Number Profiles
        </td>
        <td align="right">
          <CButton v-if="currentStep > 1 && !isRefreshingProfiles" @click="downloadCSV()" color="info" style="width: 200px; margin-top: 0px">
            Download CSV
          </CButton>
        </td>
      </tr>
    </table>


  </div>
  <div style="height: 15px"></div>

  <!-- --------------------------------------------------- -->
  <div v-if="currentStep == 1">
    <h6>Enter the phone numbers you wish to get profiles for</h6>
    <textarea v-model="numberInput" @paste="onPastedNumbers" placeholder="We'll try and intelligently parse any phone numbers from text that you paste here" style="width: 100%; height: 250px; font-family: monospace;"></textarea>
    <CButton @click="clickedStep1()" color="info" style="width: 100%; margin-top: 5px">
      Continue to the next step
    </CButton>
  </div>

  <!-- --------------------------------------------------- -->
  <div v-else-if="currentStep == 2">

    <div v-if="errorMessage">
      <div class="alert alert-danger" style="margin-top: 0px; margin-bottom: 0px" role="alert">{{ errorMessage }}</div>
    </div>

    <div v-if="isRefreshingProfiles">
      <div class="spinner-border spinner-border-sm text-primary" style="margin-top: 20px" role="status">
        <span class="sr-only"></span>
      </div>
      Loading profiles...
    </div>

    <div v-else style="overflow-x: auto;">
      <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px; width: 1400px">
        <thead>
          <tr style="font-size: 13px; border: 2px solid transparent">
            <th>Phone</th>
            <th style="text-align:center">Action</th>
            <th style="text-align:center">Reputation</th>
            <th style="text-align:center">Confidence</th>
            <th style="text-align:center">Calls</th>
            <th style="text-align:center">Allow</th>
            <th style="text-align:center">Block</th>
            <th style="text-align:center">First Seen</th>
            <th style="text-align:center">Last Seen</th>
            <th style="text-align:center">Action Updated</th>
            <th style="text-align:center">On Radar</th>
            <th style="text-align:center">Category</th>
            <th style="text-align:center">Name</th>
          </tr>
        </thead>
        <tbody style="font-size: 13px">
          <tr v-for="profile in profiles">
            <td>{{ profile.phone_number }}</td>
            <td style="text-align:center">
              <span v-if="profile.action == 'block'" class="badge badge-pill badge-danger">Block</span>
              <span v-else-if="profile.action == 'allow'" class="badge badge-pill badge-success">Allow</span>
              <span v-else class="badge badge-pill badge-secondary">{{ profile.action }}</span>
            </td>
            <td style="text-align:center">
              <span v-if="profile.reputation == 'negative'" class="badge badge-pill badge-danger">Negative</span>
              <span v-else-if="profile.reputation == 'positive'" class="badge badge-pill badge-success">Positive</span>
              <span v-else class="badge badge-pill badge-secondary">{{ profile.reputation }}</span>
            </td>
            <td style="text-align:center">
              <span class="badge badge-pill badge-secondary">{{ profile.confidence }}</span>
            </td>
            <td style="text-align:center">{{ profile.total_calls.toLocaleString() }}</td>
            <td style="text-align:center">{{ profile.votes_allow.toLocaleString() }}</td>
            <td style="text-align:center">{{ profile.votes_block.toLocaleString() }}</td>
            <td style="text-align:center">
              <div v-if="!profile.first_call">
                <span class="badge badge-pill badge-secondary">never</span>
              </div>
              <div v-else>
                <timeago :datetime="profile.first_call"></timeago>
              </div>
            </td>
            <td style="text-align:center">
              <div v-if="!profile.last_call">
                <span class="badge badge-pill badge-secondary">never</span>
              </div>
              <div v-else>
                <timeago :datetime="profile.last_call"></timeago>
              </div>
            </td>
            <td style="text-align:center">
              <div v-if="!profile.last_classified">
                <span class="badge badge-pill badge-secondary">never</span>
              </div>
              <div v-else>
                <timeago :datetime="profile.last_classified"></timeago>
              </div>
            </td>
            <td style="text-align:center">
              <span v-if="!profile.on_radar_since" class="badge badge-pill badge-secondary">No</span>
              <span v-else class="badge badge-pill badge-success">Yes</span>
            </td>
            <td style="text-align:center">
              {{ profile.category }}
            </td>
            <td style="text-align:center">
              {{ profile.name }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>


</div>
</template>


<script>
export default {
  name: 'Numbers',
  data() {
    return {
      currentStep: 1,
      errorMessage: null,
      isRefreshingProfiles: false,
      numberInput: "",
      parsedNumbers: [],
      pendingProfiles: [],
      profiles: []
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    checkPlusOne() {

      var tempNumbers = [];

      // Parse the numbers
      var missingPlusOne = false;
      this.numberInput = this.parseNumbers(this.numberInput);
      var numbers = this.numberInput.split('\n');
      for (let number of numbers) {
        if (number.substring(0, 2) != "+1") {
          missingPlusOne = true;
        }
      }

      // Make sure we have numbers
      if (this.numberInput.length == 0) {
        return;
      }

      // Check if we should add +1 to all the numbers
      if (missingPlusOne) {
        let addPlusOne = confirm("Should all the phone numbers begin with +1 ?");
        if (addPlusOne) {
          for (let number of numbers) {
            if (number.substring(0, 2) != "+1") {
              // Remove non-numeric
              number = "+1" + number.replace(/[^0-9]/g, '');
            }
            tempNumbers.push(number);
          }
        } else {
          tempNumbers = numbers;
        }
      } else {
        tempNumbers = numbers;
      }

      // Update the input field
      var output = "";
      for (let number of tempNumbers) {
        output += number + "\n";
      }
      this.numberInput = output.trim();


    },
    //--------------------------------------------------------------------------
    downloadCSV() {
      var filename = "number_profiles.csv";
      var fileContents = "phone,action,reputation,confidence,total_calls,allow_votes,block_votes,first_seen,last_seen,action_updated,on_radar_since,category,name\n";

      for (let i = 0; i < this.profiles.length; i++) {
        const profile = this.profiles[i];
        fileContents += `${profile.phone_number},${profile.action},${profile.reputation},${profile.confidence},${profile.total_calls},${profile.votes_allow},${profile.votes_block},${profile.first_call},${profile.last_call},${profile.last_classified},${profile.on_radar_since},${profile.category},${profile.name}\n`;
      }

      const blob = new Blob([fileContents], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();

      // Clean up the URL object after the file has been downloaded
      URL.revokeObjectURL(url);
    },
    //--------------------------------------------------------------------------
    onPastedNumbers(evt) {
      var text = evt.clipboardData.getData('text/plain');
      this.numberInput = this.parseNumbers(text);
      evt.preventDefault();
      window.setTimeout(this.checkPlusOne, 100);
    },
    //--------------------------------------------------------------------------
    parseNumbers(text) {
      var output = "";
      // Parse the text line by line
      var lines = text.split('\n');
      for (let line of lines) {

        // Remove non numeric except for spaces
        line = line.replace(/[^0-9\s]/g, '');

        // Split on whitespace
        var numbers = line.split(/[\s]+/);
        for (let number of numbers) {
          if (number.length > 0) {
            output += "+" + number + "\n";
          }
        }
      }

      return output.trim();
    },
    //--------------------------------------------------------------------------
    clickedStep1() {

      var tempNumbers = [];

      // Parse the numbers
      var missingPlusOne = false;
      this.numberInput = this.parseNumbers(this.numberInput);
      var numbers = this.numberInput.split('\n');
      for (let number of numbers) {
        if (number.substring(0, 2) != "+1") {
          missingPlusOne = true;
        }
      }

      // Make sure we have numbers
      if (this.numberInput.length == 0) {
        return;
      }

      // Check if we should add +1 to all the numbers
      if (missingPlusOne) {
        let addPlusOne = confirm("Should all the phone numbers begin with +1 ?");
        if (addPlusOne) {
          for (let number of numbers) {
            if (number.substring(0, 2) != "+1") {
              // Remove non-numeric
              number = "+1" + number.replace(/[^0-9]/g, '');
            }
            tempNumbers.push(number);
          }
        } else {
          tempNumbers = numbers;
        }
      } else {
        tempNumbers = numbers;
      }

      // Update the input field
      var output = "";
      for (let number of tempNumbers) {
        output += number + "\n";
      }
      this.numberInput = output.trim();

      // Dedupe the Numbers
      tempNumbers = Array.from(new Set(tempNumbers));

      // Sort the Numbers
      tempNumbers.sort();

      // Make sure we have numbers
      if (tempNumbers.length == 0) {
        return;
      }

      // Move to the second step
      this.parsedNumbers = tempNumbers;
      this.currentStep = 2;

      // download the profiles
      this.refreshProfiles();
    },
    //--------------------------------------------------------------------------
    refreshProfiles() {
      if (this.isRefreshingProfiles) {
        return;
      } else {
        this.isRefreshingProfiles = true;
        this.errorMessage = null;
        this.pendingProfiles = [...this.parsedNumbers];
        this.profiles = [];
      }

      this.fetchNextProfileBatch();
    },
    //--------------------------------------------------------------------------
    fetchNextProfileBatch() {
      if (this.pendingProfiles.length == 0) {
        this.isRefreshingProfiles = false;
        return;
      }

      // Set the maximum number of profiles to bulk fetch at once
      var maxProfiles = 500;
      var phoneNumbers = this.pendingProfiles.splice(0, maxProfiles);

      var body = JSON.stringify({
        "phone_numbers": phoneNumbers.join(","),
      });
      var that = this;
      var method = "POST";
      var url = "https://number-profile.platform.robocalls.ai/bulk-lookup";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshingProfiles
          if (code != 200) {
            // Something went wrong
            that.isRefreshingProfiles = false;
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the response
            try {
              var result = JSON.parse(body);
              that.processProfiles(result);
              that.fetchNextProfileBatch();
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
              that.isRefreshingProfiles = false;
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshingProfiles = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshingProfiles = false;
          that.errorMessage = error;
        }
      })


    },
    //--------------------------------------------------------------------------
    processProfiles(response) {
      // Make sure we received profiles
      if (!response || !response.profiles) {
        this.errorMessage = "No profiles were returned";
        this.isRefreshingProfiles = false;
        return;
      }

      this.profiles = this.profiles.concat(response.profiles);

      // Sort the profiles by last seen
      this.profiles.sort((a, b) => a.phone_number - b.phone_number);
      this.$forceUpdate();
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    }
  },
  mounted() {},
}
</script>
